.quote {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    &__author {
        font-size: rem(18);
        line-height: rem(19);
        margin-bottom: rem(26);
    }
    &__quote {
        display: flex;
        font-family: $playfair_display;
        font-weight: 400;
        font-size: rem(44);
        line-height: rem(60);
        a {
            text-decoration: none;
            //&:focus-visible {
            //    @include focus(rem(10), rem(30), relative, rem(10));
            //}
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: rem(2);
            }
        }
    }
    &__type {
        position: relative;
        font-size: rem(18);
        line-height: rem(24);
        margin-top: rem(90);
        &:before {
            position: absolute;
            width: rem(1);
            height: rem(58);
            top: rem(-12);
            left: 50%;
            transform: translateY(-100%);
            background-color: black;
            content: "";
        }
        a {
            text-decoration: none;
            &:focus-visible {
                @include focus(rem(10), rem(10), relative, rem(6));
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    //@include media-breakpoint-down(xxl) {
    //    &__author {
    //        font-size: rem(22);
    //    }
    //    &__quote {
    //        font-size: rem(52);
    //        line-height: rem(62);
    //    }
    //    &__type {
    //        font-size: rem(22);
    //    }
    //}
    //@include media-breakpoint-down(xl) {
    //    &__author {
    //        font-size: rem(20);
    //    }
    //    &__quote {
    //        font-size: rem(46);
    //        line-height: rem(56);
    //    }
    //    &__type {
    //        font-size: rem(20);
    //    }
    //}
    @include media-breakpoint-down(lg) {
        &__author {
            font-size: rem(16);
        }
        &__quote {
            font-size: rem(36);
            line-height: rem(46);
        }
        &__type {
            font-size: rem(16);
        }
    }
    @include media-breakpoint-down(md) {
        &__author {
            font-size: rem(11);
        }
        &__quote {
            font-size: rem(26);
            line-height: rem(36);
        }
        &__type {
            font-size: rem(11);
            margin-top: rem(76);
            &:before {
                height: rem(44);
            }
        }
    }
}