/* playfair-display-regular - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/public/fonts/playfair-display-v25-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-500 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('/public/fonts/playfair-display-v25-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v25-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-600 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('/public/fonts/playfair-display-v25-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v25-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-700 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/public/fonts/playfair-display-v25-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-800italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 800;
    src: local(''),
    url('/public/fonts/playfair-display-v28-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v28-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-900 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
    url('/public/fonts/playfair-display-v25-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/public/fonts/playfair-display-v25-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}