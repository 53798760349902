.progress {
    $self: &;
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;
    counter-reset: progress !important;
    margin-top: rem(30);
    &__item {
        position: relative;
        display: flex;
        flex: 1 0 auto;
        width: auto;
        justify-content: center;
        flex-flow: row nowrap;
        padding: 0 rem(10) rem(38);
        font-weight: 500;
        &:before {
            position: relative !important;
            top: auto !important;
            left: auto !important;
            font-size: rem(12);
            line-height: rem(20);
            margin-right: rem(4);
            content: counter(progress, decimal) '.' !important;
            counter-increment: progress !important;
            font-weight: 500;
        }
        &:after {
            position: absolute;
            left: 0;
            bottom: rem(8);
            width: 100%;
            height: rem(1);
            background-color: $pr_blue;
            content: "";
        }
        &:first-child {
            &:after {
                width: 50%;
                right: 0;
                left: auto;
            }
        }
        &:last-child {
            &:after {
                width: 50%;
            }
        }
        &--complete {
            #{$self}__icon {
                &:before {
                    position: absolute;
                    width: rem(34);
                    height: rem(34);
                    left: rem(-9);
                    top: rem(-9);
                    border-radius: 50%;
                    border: rem(4) solid $pr_blue;
                    content: "";
                }
            }
        }
    }
    &__label {
        font-size: rem(12);
        line-height: rem(20);
        white-space: nowrap;
        font-weight: 500;
    }
    &__icon {
        position: absolute;
        left: calc(50% - rem(8));
        bottom: 0;
        width: rem(16);
        height: rem(16);
        border-radius: 50%;
        background-color: $pr_blue;
    }
}