.search-modal {
    $self: &;
    &__dialog {
        margin-top: rem(130);
        max-width: rem(590);
        margin-right: calc((100vw - rem(1220)) / 2);
    }
    &__content {
        position: relative;
        border-radius: rem(50) 0 rem(50) 0;
        background-color: white;
        padding: rem(10) rem(30) rem(100);
        border: 0;
    }
    &__body {
        padding: rem(1);
    }
    &__top-row {
        margin-bottom: rem(44);
    }
    &__form {
        position: relative;
        input[type="search"] {
            font-size: rem(19);
            line-height: rem(26);
            color: black;
            font-weight: 500;
            padding: rem(16) rem(140) rem(16) rem(14);
            border: rem(1) solid #868686;
            border-radius: rem(8);
            height: rem(60);
        }
    }
    &__form-label {
        font-size: rem(19);
        color: #767676;
        font-weight: 500;
    }
    &__form-input {
        //font-size: rem(19);
        //line-height: rem(26);
        //color: black;
        //font-weight: 500;
        //padding: rem(16) rem(140) rem(16) rem(14);
        //border: rem(1) solid #868686;
        //border-radius: rem(8);
    }
    &__form-submit {
        position: absolute !important;
        right: rem(9);
        bottom: rem(9);
    }
    &__all {
        display: flex;
        justify-content: flex-end;
        padding-bottom: rem(12);
    }
    &__close-button {
        position: absolute;
        right: rem(40);
        bottom: rem(48);
        opacity: 1;
    }
    &__inner-col {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: rem(24);
    }
    &__col-title {
        font-weight: 500;
        font-size: rem(32);
        line-height: rem(40);
        margin-bottom: rem(24);
    }
    &__list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0;
    }
    &__li {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        border-bottom: rem(1) solid rgba(#0F1D37, .3);
        &:last-child {
            border-bottom-color: transparent;
        }
        &:hover {
            #{$self}__list-item {
                color: $pr_blue;
                font-weight: 600;
            }
            #{$self}__list-item-chevron {
                background-color: $pr_red;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    &__list-item {
        font-size: rem(16);
        line-height: rem(26);
        padding: rem(6) 0;
        color: $txt_dark_grey;
        font-weight: 500;
        text-decoration: none;
        width: 100% ;
        &:focus-visible {
            &:before {
                top: 0;
            }
            @include focus(rem(40), rem(30));
        }
    }
    &__list-item-chevron {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        right: 0;
        width: rem(22);
        height: rem(22);
        border-radius: 50%;
        transform: translateX(rem(6)) translateY(-50%);
        svg {
            transform: translateX(rem(1));
        }
    }
    @media (max-width: 1550px) {
        &__dialog {
            margin-right: rem(20);
        }
    }
    @include media-breakpoint-down(xxl) {
        &__dialog {
            margin: rem(70) rem(20) 0 auto;
        }
        &__content {
            padding: rem(30) rem(20) rem(100);
        }
        &__close-button {
            right: rem(40);
        }
        &__list-item {
            font-size: rem(18);
            line-height: rem(26);
            padding: rem(4) 0;
        }
    }
    @include media-breakpoint-down(xl) {
        &__content {
            padding: rem(30) rem(20) rem(60);
        }
        &__top-row {
            margin-bottom: rem(20);
        }
        &__col-title {
            font-size: rem(22);
            line-height: rem(28);
            margin-bottom: rem(16);
        }
        &__form-label {
            font-size: rem(17) !important;
            left: rem(27) !important;
            bottom: rem(19) !important;
        }
        .form__field {
            &--filled {
                > label {
                    transform: translateX(rem(-27)) translateY(rem(-46));
                    font-size: rem(17);
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &__content {
            padding: rem(30) rem(20) rem(60);
        }
        &__form {
            margin-bottom: rem(26);
        }
        &__top-row {
            margin-bottom: 0;
        }
        &__inner-col {
            margin-bottom: rem(24);
        }
    }
    @include media-breakpoint-down(md) {
        &__dialog {
            margin: rem(66) rem(20) 0 rem(20);
        }
        &__top-row {
            div[class^="col-"] {
                padding-left: rem(20);
                padding-right: rem(20);
            }
        }
        &__content {
            border-radius: rem(20) 0 rem(20);
            padding: rem(30) 0 rem(70);
        }
        &__body {
            padding: 0;
        }
        &__inner-col {
            margin-bottom: rem(16);
        }
        &__col-title {
            font-size: rem(18);
            line-height: rem(24);
            margin-bottom: rem(6);
        }
        &__list-item {
            font-size: rem(15);
            line-height: rem(20);
            padding: rem(5) 0;
        }
        &__close-button {
            font-size: rem(14);
            right: rem(20);
            bottom: rem(36);
            padding-right: rem(30);
            background: transparent url("/public/svg/close-small.svg") top right / rem(23) no-repeat;
        }
        &__form {
            input[type="search"] {
                padding: rem(8) rem(15);
                font-size: rem(16);
                line-height: rem(22);
                border-radius: rem(5);
                height: rem(40);
            }
        }
        &__form-label {
            font-size: rem(11) !important;
            line-height: rem(18) !important;
            margin-bottom: 0 !important;
            bottom: rem(12) !important;
            left: rem(15) !important;
        }
        .form__field {
            &--filled {
                > label {
                    transform: translateX(rem(-15)) translateY(rem(-28));
                }
            }
        }
        &__form-input {
            //padding: rem(8) rem(15);
            //font-size: rem(16);
            //line-height: rem(22);
            //border-radius: rem(5);
        }
        &__form-submit {
            bottom: rem(5);
            right: rem(5);
            height: rem(30);
            padding: 0 rem(32) 0 rem(12);
            font-size: rem(11);
            border-radius: rem(20);
            &:after {
                top: rem(5);
                right: rem(5);
                width: rem(20);
                height: rem(20);
            }
        }
    }
}