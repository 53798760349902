.article-slider {
    $self: &;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: rem(20) 0 rem(78);
    &__wrapper {
        max-width: rem(488);
        overflow: visible;
    }
    &__slide {
        padding: 0 rem(12);
        &--file {
            padding: 0;
            margin-bottom: rem(22);
            #{$self}__slide-inner {
                height: auto;
                flex-flow: column nowrap;
                text-decoration: none;
                border-radius: 0;
                &:hover,
                &:focus-visible {
                    #{$self}__image {
                        opacity: 1;
                    }
                    #{$self}__bottom-title {
                        text-decoration: underline;
                    }
                }
                &:before {
                    display: none;
                }
            }
            #{$self}__image-container {
                position: relative;
                height: rem(323);
                border-radius: rem(20);
                overflow: hidden;
                &:before {
                    background-color: black;
                    opacity: .4;
                    mix-blend-mode: normal;
                }
                &:after {
                    background-color: $gr1_green;
                }
            }
            #{$self}__title {
                font-size: rem(46);
                line-height: rem(50);
                font-weight: 400;
            }
            &--small {
                #{$self}__slide-inner {
                    &:hover {
                        #{$self}__bottom-title {
                            text-decoration-thickness: rem(1);
                        }
                    }
                }
                #{$self}__image-container {
                    height: rem(324);
                }
                #{$self}__title {
                    font-size: rem(37);
                    line-height: rem(42);
                }
                #{$self}__bottom-title {
                    font-size: rem(22);
                    line-height: rem(25);
                    color: white;
                }
            }
            &--dark {
                #{$self}__slide-inner {
                    &:hover {
                        #{$self}__image-container {
                            &:before {
                                opacity: .2;
                            }
                        }
                    }
                }
                #{$self}__image-container {
                    &:before {
                        opacity: .5;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &__slide-inner {
        display: flex;
        position: relative;
        width: 100%;
        height: rem(400);
        border-radius: rem(20);
        overflow: hidden;
        &:hover,
        &:focus-visible {
            #{$self}__image-container {
                &:before,
                &:after {
                    opacity: 0;
                }
            }
            #{$self}__image {
                opacity: .7;
            }
            #{$self}__title {
                text-decoration: underline;
                text-decoration-thickness: rem(2);
            }
        }
        &:focus-visible {
            #{$self}__content-container {
                border-color: white;
            }
        }
    }
    &__image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        //border: rem(6) solid transparent;
        //transition: border .3s ease;
        &:before,
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #54A176;
            opacity: .5;
            content: "";
            z-index: 2;
            border-radius: rem(20);
            transition: opacity .3s ease;
        }
        &:before {
            mix-blend-mode: multiply;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        transition: opacity .3s ease;
    }
    &__content-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: rem(20);
        text-align: center;
        border: rem(3) solid transparent;
        border-radius: 20px;
        transition: border .3s ease;
    }
    &__subtitle {
        font-size: rem(19);
        line-height: rem(23);
        color: white;
        font-weight: 300;
        margin-bottom: rem(10);
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(30);
        line-height: rem(37);
        color: white;
        font-weight: 700;
        text-decoration-thickness: rem(1);
        text-decoration-color: transparent;
        transition: all .3s ease;
    }
    &__tags {
        display: flex;
        flex-flow: row nowrap;
        margin-top: rem(10);
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
        li {
            font-size: rem(18);
            line-height: rem(26);
            padding: 0 rem(10);
            color: white;
            border-left: rem(2) solid white;
            &:first-child {
                border-left: 0;
            }
        }
    }
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(22) rem(15);
    }
    &__bottom-title {
        font-family: $playfair_display;
        font-weight: 500;
        font-size: rem(22);
        line-height: rem(25);
        text-align: center;
    }
    &__navigation {
        top: rem(202);
        &--related {
            display: none;
        }
        &--files {
            display: none;
        }
    }
    &__pagination {
        bottom: rem(0) !important;
    }
    &__news-pagination {
        bottom: rem(0) !important;
    }
    &__news-slide {
        padding: 0 rem(10);
        height: 100%;
    }
    &__related-article-slide {
        padding: 0 rem(10)
    }
    &__files-slider,
    &__files-slider-in-blue {
        > .row {
            margin-left: 0;
        }
    }
    .swiper-slide {
        &.swiper-slide-active {
            //#{$self}__image-container {
            //    &:before,
            //    &:after {
            //        opacity: 0;
            //    }
            //}
            //#{$self}__image {
            //    opacity: .7;
            //}
            //#{$self}__content-container {
            //    border-color: white;
            //}
        }
    }
    // Version for 'Ontdek Apeldoorn'
    &--discover {
        padding: rem(20) 0 0 0;
        #{$self}__wrapper {
            max-width: rem(800);
        }
        #{$self}__slide-inner {
            display: flex;
            flex-flow: column nowrap;
            height: auto;
            text-decoration: none;
            &:hover {
                #{$self}__title {
                    text-decoration: underline !important;
                    text-decoration-thickness: rem(2) !important;
                }
            }
            &:focus-visible {
                &:before {
                    border-radius: rem(20);
                    border: rem(3) solid $se_focus;
                    z-index: 1;
                }
                #{$self}__content-container {
                    border: 0;
                }
            }
        }
        #{$self}__image-container {
            position: relative;
            height: rem(487);
            border-radius: rem(20);
            overflow: hidden;
            order: 1;
            &:before,
            &:after {
                display: none;
            }
        }
        #{$self}__image {
            opacity: 1 !important;
        }
        #{$self}__content-container {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: auto;
            height: auto;
            top: auto;
            left: auto;
            padding: rem(30) rem(20) rem(30);
            border: 0 !important;
            order: 2;
        }
        #{$self}__subtitle {
            color: black;
            order: 1;
        }
        #{$self}__title {
            font-size: rem(22);
            line-height: rem(33);
            color: black !important;
            font-weight: 400;
            text-decoration-thickness: rem(1) !important;
            text-decoration: none !important;
            max-width: rem(446);
            order: 2;
        }
        .swiper-slide {
            max-width: rem(390);
            width: 100%;
            transition: max-width .5s ease;
            &.swiper-slide-active {
                max-width: rem(488);
                #{$self}__content-container {
                    border: 0;
                }
            }
        }
        .slider__navigation {
           top: rem(244);
        }
    }
    &--news-slider {
        padding: rem(42) 0 rem(90) 0;
        #{$self}__wrapper {
            max-width: 100%;
            overflow: hidden;
            padding: rem(10) 0;
        }
        #{$self}__navigation {
            top: rem(280);
        }
        .swiper-slide {
            height: auto !important;
        }
    }
    &--files {
        padding: rem(100) 0 rem(67);
        .swiper-slide {
            &.swiper-slide-active {
                #{$self}__image-container {
                    &:before {
                        opacity: 0 !important;
                    }
                    &:after {
                        opacity: 0 !important;
                    }
                }
                #{$self}__image {
                    opacity: 1;
                }
                #{$self}__bottom-title {
                    text-decoration: underline;
                }
            }
        }
        &--in-blue {
            padding: rem(80) 0 0 0;
            > .container {
                max-width: 1440px;
            }
            .swiper-slide {
                &.swiper-slide-active {
                    #{$self}__image-container {
                        &:before {
                            opacity: .4 !important;
                        }
                        &:after {
                            opacity: .5 !important;
                        }
                    }
                    #{$self}__bottom-title {
                        text-decoration: none;
                    }
                }
            }
        }
        &--is-slider {
            .swiper-wrapper {
                flex-wrap: nowrap !important;
            }
            .article-slider__navigation--files {
                display: flex;
            }
        }
    }
    &--related-articles {
        padding: rem(60) 0;
    }
    &--narrow {
        padding-top: rem(45);
        .article__text-container {
            height: rem(275) !important;
        }
    }
    &--no-swiper {
        display: flex;
        #{$self}__related-article-slide {
            width: 25%;
        }
    }
    @include media-breakpoint-down(xxl) {
        &__slide {
            &--file {
                #{$self}__title {
                    font-size: rem(40);
                    line-height: rem(44);
                }
            }
        }
        &--files {
            &--in-blue {
                padding: rem(40) 0 0 0;
            }
        }
    }
    @include media-breakpoint-down(xl) {
        padding: rem(20) 0 rem(58);
        &--discover {
            padding: 0;
        }
        &__wrapper {
            max-width: rem(500);
        }
        &__navigation {
            top: rem(202);
        }
        &__slide {
            &--file {
                margin-bottom: rem(15);
                #{$self}__image-container {
                    height: rem(218);
                }
                #{$self}__title {
                    font-size: rem(22);
                    line-height: rem(26);
                }
                #{$self}__bottom-title {
                    font-size: rem(20);
                    line-height: rem(23);
                }
                &--small {
                    #{$self}__bottom-title {
                        font-size: rem(20);
                        line-height: rem(23);
                    }
                }
            }
        }
        &--discover {
            #{$self}__wrapper {
                max-width: rem(700);
            }
            #{$self}__image-container {
                height: rem(486);
            }
            .swiper-slide {
                max-width: rem(420);
                &.swiper-slide-active {
                    max-width: rem(534);
                }
            }
            .slider__navigation {
                top: rem(243);
            }
        }
        &--news-slider {
            padding: rem(22) 0 rem(60) 0;
        }
        &--files {
            padding: rem(60) 0 rem(30);
            &--in-blue {
                padding: rem(40) 0 0 0;
            }
        }
        &--related-articles {
            padding: rem(40) 0;
        }
        &--no-swiper {
            flex-flow: row wrap;
            #{$self}__related-article-slide {
                width: 50%;
            }
            .article--in-news-articles {
                margin-bottom: 0;
            }
        }
        &--narrow {
            .article__text-container,
            .article__image-container {
                min-height: rem(230) !important;
                height: rem(230) !important;
            }
            .article__text {
                font-size: rem(22);
                line-height: rem(28);
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding: rem(20) 0 rem(38);
        &__wrapper {
            max-width: rem(350);
        }
        &__slide-inner {
            height: rem(316);
        }
        &__title {
            font-size: rem(28);
            line-height: rem(32);
        }
        &__tags {
            li {
                font-size: rem(18);
                line-height: rem(20);
            }
        }
        &__navigation {
            top: rem(162);
            &--related {
                display: flex;
            }
        }
        &__related-article-slide {
            padding: 0 rem(5)
        }
        &--discover {
            #{$self}__wrapper {
                max-width: rem(600);
            }
            #{$self}__image-container {
                height: rem(386);
            }
            #{$self}__content-container {
                padding: rem(20) rem(10) rem(20);
            }
            #{$self}__subtitle {
                font-size: rem(20);
                line-height: rem(26);
                margin-bottom: rem(12);
            }
            #{$self}__title {
                font-size: rem(22);
                line-height: rem(32);
                max-width: rem(266);
            }
            .swiper-slide {
                max-width: rem(320);
                &.swiper-slide-active {
                    max-width: rem(434);
                }
            }
            .slider__navigation {
                top: rem(193);
            }
        }
        &__related-articles-slider {
            overflow: visible;
        }
        &--related-articles {
            padding: rem(20) 0;
            > .container {
                max-width: 76vw;
            }
        }
        &--files {
            padding: rem(60) 0 rem(30);
            &--in-blue {
                padding: rem(40) 0 0 0;
            }
            .swiper-wrapper {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
        &--no-swiper {
            padding-top: rem(20);
        }
    }
    @include media-breakpoint-down(md) {
        padding: rem(20) 0 rem(38);
        &__wrapper {
            max-width: rem(248);
        }
        &__slide {
            padding: 0 rem(6);
            &--file {
                padding: 0 !important;
                #{$self}__title {
                    font-size: rem(24);
                    line-height: rem(24);
                }
                #{$self}__bottom {
                    padding: rem(10) rem(10);
                }
                #{$self}__bottom-title {
                    font-size: rem(14);
                    line-height: rem(18);
                }
            }
            &--small {

            }
        }
        &__slide-inner {
            height: rem(236);
        }
        &__title {
            font-size: rem(26);
            line-height: rem(28);
            text-decoration: underline;
            font-weight: 500;
        }
        &__tags {
            margin-top: 0;
            li {
                padding: 0 rem(8);
                font-size: rem(11);
                line-height: rem(16);
            }
        }
        &__navigation {
            top: rem(120);
            &--files {
                display: flex;
                top: rem(114);
            }
        }
        &__files-slider,
        &__files-slider-in-blue {
            .swiper-wrapper {
                flex-wrap: nowrap !important;
            }
            overflow: visible;
            #{$self}__wrapper {
                overflow: visible;
            }
        }
        .swiper-slide {
            &.swiper-slide-active {
                #{$self}__image-container {
                    &:before,
                    &:after {
                        opacity: .5;
                    }
                }
                #{$self}__image {
                    opacity: 1;
                }
                #{$self}__content-container {
                    border-color: transparent;
                }
            }
        }
        &--discover {
            #{$self}__wrapper {
                max-width: rem(300);
            }
            #{$self}__image-container {
                height: rem(250);
            }
            #{$self}__content-container {
                padding: rem(15) rem(0) rem(0);
            }
            #{$self}__subtitle {
                font-size: rem(11);
                line-height: rem(13);
                margin-bottom: rem(12);
            }
            #{$self}__title {
                font-size: rem(14);
                line-height: rem(18);
                max-width: rem(187);
            }
            .swiper-slide {
                max-width: rem(207);
                &.swiper-slide-active {
                    max-width: rem(248);
                }
            }
            .slider__navigation {
                top: rem(125);
            }
        }
        &--news-slider {
            #{$self}__wrapper {
                overflow: visible;
            }
            .swiper-slide {
                opacity: .5;
                transition: opacity .3s ease;
                &.swiper-slide-active {
                    opacity: 1;
                }
            }
        }
        &--files {
            padding: rem(60) 0 rem(30);
            &--in-blue {
                padding: rem(30) 0 0 0;
                .swiper-slide {
                    &.swiper-slide-active {
                        #{$self}__image-container {
                            &:before {
                                opacity: 0 !important;
                            }
                            &:after {
                                opacity: 0 !important;
                            }
                        }
                        #{$self}__bottom-title {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .container {
                max-width: 67vw;
            }
        }
        &--no-swiper {
            .article__image-container,
            .article__text-container {
                height: rem(154) !important;
                min-height: rem(154) !important;
            }
            .article__text {
                padding: 0 !important;
                font-size: rem(18);
                line-height: rem(24);
            }
        }
        &--narrow {
            padding-top: rem(30);
            .article__text {
                padding: 0 rem(30);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &__slide {
            &--file {
                #{$self}__image-container {
                    height: rem(195);
                }
            }
        }
        &--news-slider {
            .container {
                max-width: 67vw;
            }
            #{$self}__navigation {
                top: rem(170);
            }
        }
    }
}