.how-to-help {
    $self: &;
    position: absolute;
    top: 0;
    left: calc((100vw - rem(1730)) / 2);
    left: calc((100% - rem(1680)) / 2);
    max-width: rem(786);
    width: 100%;
    height: 100%;
    &__el {
        position: absolute;
        top: 50%;
        z-index: 3;
        display: flex;
        align-items: center;
        transform: translateY(-50%);
    }
    &__block {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        max-width: 100%;
        background: rgb(41,162,164);
        background: linear-gradient(135deg, rgba(41,162,164,1) 0%, rgba(8,127,70,0.8) 100%);
        border-radius: rem(55) 0 rem(55) 0;
    }
    &__title-container {
        padding: rem(30) rem(14) rem(20) rem(46);
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(40);
        line-height: 1;
        color: white;
        font-weight: 900;
    }
    &__form {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        padding: 0 rem(20);
    }
    &__form-input-container {
        position: relative;
        display: flex;
        height: rem(56);
        width: 100%;
        border-top: rem(1) solid rgba(black, .3);
        border-bottom: rem(1) solid rgba(black, .3);
    }
    &__form-label {
        position: absolute;
        left: 10px;
        top: rem(17);
        font-size: rem(16);
        line-height: 1.3;
        font-weight: 700;
        color: black;
        z-index: 1;
        margin-bottom: 0;
    }
    &__form-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: rem(54);
        padding: 0 rem(30) 0 rem(70);
        font-size: rem(16);
        line-height: 1.3;
        color: #646464;
        border: 0;
    }
    &__form-submit {
        position: absolute !important;
        top: rem(3);
        right: rem(10);
        width: rem(50);
        height: rem(50);
        border: 0;
        z-index: 3;
        background: url("/public/svg/search-icon.svg") center center / rem(23) no-repeat;
        &:before {
            top: 0;
        }
    }
    &__tags {
        padding: rem(30) rem(46) rem(32);
        .tag{
            color: white;
        }
    }
    &__close {
        display: none;
    }
    &__form-container {
        position: relative;
    }
    &__search-results {
        position: absolute;
        top: rem(56);
        left: rem(-20);
        padding: 0 rem(20);
        width: calc(100% + rem(40));
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background: white;
        border-radius: 0 0 rem(20) rem(20);
        opacity: 0;
        visibility: hidden;
        height: 0;
        transition: opacity .3s ease, visibility .3s ease;
        ul li a {
            font-size: rem(14);
            line-height: rem(20);
            padding: rem(6) 0;
        }
        &--show {
            height: rem(300);
            opacity: 1;
            visibility: visible;
        }
    }
    @media (max-width: 1300px) {
        left: rem(100)
    }
    @media (max-height: 820px) {
        margin-top: 0;
    }
    //@include media-breakpoint-down(xxl) {
    //    max-width: rem(340);
    //    &__block {
    //        max-width: rem(340);
    //        border-radius: rem(26) 0 rem(26) 0;
    //    }
    //    &__title-container {
    //        padding: rem(30) rem(20) rem(20) rem(32);
    //    }
    //    &__title {
    //        font-size: rem(42);
    //    }
    //    &__tags {
    //        padding: rem(32) rem(22) rem(30);
    //    }
    //    &__form-input-container {
    //        height: rem(53);
    //    }
    //    &__form-label {
    //        font-size: rem(19);
    //        top: rem(14);
    //    }
    //    &__form-input {
    //        font-size: rem(19);
    //        padding-left: rem(69);
    //        height: rem(51);
    //    }
    //    &__form-submit {
    //        top: rem(1);
    //    }
    //}
    @include media-breakpoint-down(xl) {
        width: rem(280);
        &__block {
            padding-top: rem(6);
        }
        &__title-container {
            padding: rem(20) rem(20) rem(15) rem(30);
        }
        &__title {
            font-size: rem(32);
            line-height: rem(34);
        }
        &__tags {
            padding: rem(25) rem(14) rem(25) rem(22);
        }
        &__form {
            padding: 0 rem(13);
        }
        &__form-input-container {
            height: rem(48);
        }
        &__form-label {
            font-size: rem(14);
            top: rem(14);
        }
        &__form-input {
            font-size: rem(14);
            padding-left: rem(53);
            padding-right: rem(20);
            height: rem(46);
        }
        &__form-submit {
            top: rem(1);
            right: rem(5);
            width: rem(38);
            height: rem(46);
            background-size: rem(18);
        }
        &__search-results {
            top: rem(46);
            padding: 0 rem(13);
            left: rem(-13);
            width: calc(100% + rem(26));
        }
    }
    @include media-breakpoint-down(lg){
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translateX(-50%) translateY(-50%) scale(.9);
        height: auto;
        width: rem(236);
        opacity: 0;
        visibility: hidden;
        &--show {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) translateY(-50%) scale(1);
            transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
            //#{$self}__el {
            //    opacity: 1;
            //    visibility: visible;
            //    transform: translateY(0) scale(1);
            //    transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
            //}
        }
        &__el {
            position: relative;
            margin-top: 0;
            top: auto;
            transform: translateY(0);
            //transform: translateY(0) scale(.9);
            //opacity: 0;
            //visibility: hidden;
            //transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
        }
        &__title-container {
            padding: rem(0) rem(20) rem(15) rem(30);
            margin-top: rem(-10);
        }
        &__close {
            display: inline-flex;
            align-self: flex-end;
            opacity: 1;
            visibility: visible;
            justify-content: flex-end;
            height: rem(40);
            font-size: rem(11);
            line-height: rem(20);
            font-weight: 500;
            padding-right: rem(26);
            margin-right: .6rem;
            background: transparent url("/public/svg/close-small.svg") center right / rem(20) no-repeat;
            &:focus-visible {
                @include focus($pos: relative);
            }
        }
    }
    @include media-breakpoint-down(md) {
        //max-width: rem(236);
        &__search-results {
            height: rem(200);
        }
    }
}