.persons,
.folders {
    padding: rem(80) 0 rem(40);
    @include media-breakpoint-down(md) {
        padding: rem(30) 0 0;
        padding-right: rem(20) !important;
        padding-left: rem(20) !important;
    }
}

.person,
.folder {
    $self: &;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    text-decoration: none;
    margin-bottom: rem(22);
    &__content {
        order: 2;
        display: flex;
        flex-flow: column nowrap;
        padding: rem(24);
        justify-content: center;
        align-items: center;
    }
    &__title {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        font-family: $playfair_display;
        font-size: rem(22);
        line-height: rem(25);
        color: black;
        font-weight: 500;
        text-align: center;
    }
    &__image-container {
        order: 1;
        position: relative;
        width: 100%;
        height: rem(322);
        border-radius: rem(20);
        overflow: hidden;
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__image-content {
        display: flex;
        align-items: center;
        min-height: rem(80);
        margin-top: rem(24);
        padding: 0 rem(16);
    }
    &__image-title {
        position: relative;
        font-size: rem(40);
        line-height: 1;
        font-weight: 500;
        color: white;
        text-align: center;
    }
    &__icon {
        position: relative;
        z-index: 2;
        width: rem(100);
    }
    &--with-icon {
        #{$self}__image-container {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding-top: rem(74);
            background: rgb(14,113,58);
            background: linear-gradient(45deg, rgba(14,113,58,1) 0%, rgba(74,157,169,1) 100%);
            &:before {
                position: absolute;
                bottom: rem(-48);
                left: rem(-48);
                width: calc(100% + rem(96));
                height: 0;
                padding-bottom: calc((100% + rem(96)) * 1.1);
                content: "";
                background: url("/public/svg/tile-artwork.svg") center center / 100% no-repeat;
            }
        }
    }
    &:hover {
        .person__title,
        .folder__title,
        .person__image-title,
        .folder__image-title {
            text-decoration: underline !important;
            text-decoration-thickness: rem(1) !important;
        }
        .person__image-title,
        .folder__image-title {
            text-decoration-thickness: rem(2) !important;
        }
    }
    @include media-breakpoint-down(xl) {
        &__image-container {
            height: rem(250);
        }
        &__icon {
            width: rem(50);
        }
        &__image-content {
            margin-top: rem(16);
            min-height: rem(60);
        }
        &__image-title {
            font-size: rem(30);
        }
        &__content {
            padding: rem(16)
        }
        &__title {
            font-size: rem(18);
            line-height: rem(22);
        }
        &--with-icon {
            #{$self}__image-container {
                padding-top: rem(62);
                &:before {
                    bottom: rem(-64)
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &__image-container {
            height: rem(183);
        }
        &__icon {
            width: rem(60);
        }
        &__image-content {
            margin-top: rem(16);
            min-height: rem(40);
        }
        &__image-title {
            font-size: rem(20);
        }
        &--with-icon {
            #{$self}__image-container {
                padding-top: rem(42);
                &:before {
                    bottom: rem(-96)
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        &__content {
            padding: rem(10) 0;
        }
        &__title {
            font-size: rem(16);
            line-height: rem(20);
        }
        &__image-container {
            border-radius: rem(10);
            height: rem(143);
        }
        &__image-content {
            min-height: 0;
        }
        &--with-icon {
            #{$self}__image-container {
                height: rem(154);
                padding-top: rem(30);
                &:before {
                    bottom: rem(-192)
                }
            }
        }
    }
    @media (max-width: 400px) {
        &--with-icon {
            #{$self}__image-container {
                &:before {
                    bottom: rem(-112)
                }
            }
        }
    }
}

.folder {
    &__icon {
        order: 1;
    }
    &__image-content {
        order: 2;
    }
}