.tags {
    display: flex;
    flex-flow: row wrap;
}
.tag {
    display: flex;
    align-items: center;
    height: rem(31);
    padding: 0 rem(14);
    border: rem(1) solid #b6bac2;
    border-radius: rem(5);
    margin: 0 rem(6) rem(6) 0;
    font-size: rem(12);
    line-height: rem(15);
    color: black;
    text-decoration: none;
    border-width: rem(1) !important;
    &:focus-visible {
        @include focus(rem(5), 0, relative);
        border: rem(1) solid transparent !important;
    }
    &:hover {
        color: white !important;
        background-color: $pr_green;
        border-color: $pr_green;
    }
    &--alert {
        color: white !important;
        background-color: $pr_orange;
        border-color: $pr_orange;
    }
    &--large-rounded {
        height: rem(42);
        font-size: rem(15);
        line-height: rem(21);
        border-radius: rem(21);
        border-color: #8D8D8D;
        padding: 0 rem(20);
        margin-right: rem(10);
    }
    @include media-breakpoint-down(xl) {
        height: rem(28);
        padding: 0 rem(12);
        margin: 0 rem(5) rem(5) 0;
        font-size: rem(11);
        line-height: rem(14);
    }
}