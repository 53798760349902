.cta-side-block {
    $self: &;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-flow: column nowrap;
    background-color: $pr_orange;
    border-radius: rem(45) 0 rem(45) 0;
    padding: rem(50) rem(20);
    max-width: rem(280);
    &__title {
        font-family: $playfair_display !important;
        font-size: rem(34) !important;
        line-height: rem(37) !important;
        font-weight: 900 !important;
        text-align: center;
        padding-top: 0 !important;
        margin-bottom: 0 !important;
        &--white {
            color: white;
        }
    }
    &__link {
        font-size: rem(18);
        line-height: 1;
        color: white !important;
        font-weight: 700;
        text-align: center;
        margin-top: rem(25);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &--large {
            font-size: rem(22);
            color: black !important;
            margin-top: 0;
            &:hover {
                color: white !important;
            }
        }
    }
    &__outer {
        position: relative;
        height: 0;
    }
    &__list {
        margin-top: rem(24);
        border-bottom: rem(1) solid white;
        padding-left: 0;
        li {
            border-top: rem(1) solid white;
            padding: rem(16) 0;
            text-align: center;
            &:before {
                display: none;
            }
        }
    }
    &__image {
        max-height: rem(70);
        width: auto;
        padding: rem(15) rem(30);
        background-color: white;
        border-radius: rem(28);
        margin-top: rem(12);
        align-self: center;
    }
    &--between {
        top: 50%;
        transform: translateY(-50%) translateX(100%);
        right: rem(-70);
    }
    &--in-wide-image {
        right: rem(-70);
        &--top {
            top: rem(-190);
        }
        &--bottom {
            bottom: rem(-56);
        }
    }
    &--on-page-header {
        bottom: rem(90);
        transform: translateY(50%);
        right: calc((100vw - rem(1180)) / 2);;
        text-align: center;
        padding: rem(25) rem(24);
        #{$self}__title {
            color: white;
            margin-bottom: rem(20) !important;
            font-size: rem(30) !important;
        }
        p {
            font-size: rem(17);
            line-height: rem(21);
        }
        &--top-aligned {
            bottom: auto;
            top: rem(30);
            transform: translateY(0);
        }
        &--bottom-aligned {
            bottom: rem(100);
            transform: translateY(100%);
        }
    }
    p {
        text-align: center;
        margin-bottom: 0;
        font-size: rem(16);
        line-height: rem(20);
        font-weight: 500;
        a {
            display: inline-block;
            font-weight: 700;
            text-decoration: underline;
            //margin: rem(18) 0;
        }
    }
    @media (max-width: 1765px) {
        &--in-wide-image {
            right: rem(40);
            &--top {
                top: rem(-60);
            }
        }
    }
    @include media-breakpoint-down(xxl) {
        &__title {
            font-size: rem(30) !important;
            line-height: rem(34) !important;
        }
    }
    @media (max-width: 1380px) {
        &__outer {
            height: auto;
            display: flex;
            justify-content: center;
        }
        &--between {
            position: relative;
            top: auto !important;
            bottom: auto !important;
            right: auto !important;
            transform: translateX(0) translateY(0);
            margin-top: 0;
            margin-bottom: rem(30);
        }
    }
    @media (max-width: 1260px) {
        &--on-page-header {
            right: rem(20);
            max-width: rem(260);
            p {
                font-size: rem(14);
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding: rem(30) rem(20);
        max-width: rem(240);
        &__title {
            font-size: rem(24) !important;
            line-height: rem(24) !important;
        }
        &__link {
            font-size: rem(14);
            margin-top: rem(20);
        }
        &--on-page-header {
            position: relative;
            top: auto !important;
            bottom: auto !important;
            right: auto !important;
            transform: translateY(0);
            width: 100%;
            max-width: 100%;
            border-radius: 0;
            padding: rem(16) rem(40);
            order: 1;
            align-items: center;
            #{$self}__title {
                font-size: rem(16) !important;
                line-height: 1.3 !important;
                margin-bottom: rem(10) !important;
            }
            p {
                font-size: rem(11);
                line-height: rem(16);
                max-width: rem(640);
            }
        }
        &__image {
            max-height: rem(50);
            width: auto;
            max-width: rem(150);
            padding: rem(10) rem(20);
            background-color: white;
            border-radius: rem(28);
            margin-top: rem(10);
        }
    }
    @include media-breakpoint-down(md) {
        position: relative;
        top: auto !important;
        bottom: auto !important;
        right: auto !important;
        margin-top: 0;
        margin-bottom: rem(30);
        &--on-page-header {
            margin-bottom: 0;
        }
    }
}

// Follow ups
@include media-breakpoint-down(md) {
    .cta-side-block--in-wide-image + .editor__image-container {
        margin-top: rem(-60);
    }
}