html {
    //@include media-breakpoint-up(lg) {
    //    zoom: .75;
    //}
}

body {
    font-family: $interstate;
    color: #000000;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}

.main {
    width: 100%;
}

.placeholder {
    position: relative;
    display: flex;
    width: 100%;
    height: rem(900);
    background-color: $se_grey;
}

.skiplink {
    position: absolute !important;
    top: rem(20);
    left: rem(20);
    z-index: 0;
    opacity: 0;
    &:focus-visible {
        z-index: 1000;
        opacity: 1;
    }
    @include media-breakpoint-down(sm) {
        top: rem(3);
        left: rem(10);
    }
}

.scroll-to-point {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: rem(-80);
    left: 0;
    width: rem(1);
    height: rem(1);
    @include media-breakpoint-down(lg) {
        top: rem(-46);
    }
}