.section {
    $self: &;
    position: relative;
    width: 100%;
    > .container {
        position: relative;
    }
    &__title-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(69);
        line-height: rem(73);
        font-weight: 900;
        color: black;
        text-align: center;
        &--medium {
            font-size: rem(54);
            line-height: rem(60);
            font-weight: 500;
            &--heavy {
                font-weight: 900;
            }
        }
        &--slider-small {
            font-size: rem(46);
            line-height: rem(60);
            font-weight: 500;
        }
        &--white {
            color: white;
        }
        &--small {
            font-size: rem(54);
            line-height: rem(60);
        }
        &--light {
            font-weight: 400;
        }
        &--article {
            font-weight: 400;
        }
    }
    &__intro {
        font-weight: 400;
        font-size: rem(22);
        line-height: rem(30);
        color: black;
        margin-top: rem(30);
        text-align: center;
        margin-bottom: rem(19);
        &--white {
            color: white;
        }
        &--regular {
            font-weight: 500;
        }
    }
    &__more {
        display: flex;
        justify-content: center;
    }
    &__more-link {
        font-family: $interstate;
        font-size: rem(18);
        line-height: rem(26);
        color: black;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &__hash-list {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        margin-top: rem(18);
        margin-bottom: rem(20);
        padding-left: 0;
        list-style-type: none;
        &--in-hero-slider {
            margin-top: 0;
            #{$self}__hash {
                color: white;
                font-size: rem(18);
                line-height: rem(22);
                font-weight: 500;
            }
        }
    }
    &__hash-li {
        padding: 0 rem(18);
    }
    &__hash {
        font-size: rem(22);
        line-height: rem(30);
        font-weight: 300;
        color: black;
        text-decoration: none;
        &:not(span):hover {
            text-decoration: underline;
        }
    }
    &--border-radius {
        border-radius: rem(75) 0 rem(75) 0;
    }
    &--border-bottom-right-radius {
        padding: 0 0 rem(67);
        border-bottom-right-radius: rem(75);
    }
    // Colors
    &--grey {
        background-color: $se_grey;
        margin-left: calc(100% - 80%);
        .container{
            left: -20%;
        }
        &:last-of-type {
            border-radius: 0 0 rem(75) 0;
        }
    }
    &--blue-gradient {
        background: rgb(104,185,213);
        background: linear-gradient(218deg, rgba(41,162,164,1) 0%, rgba(8,127,70,1) 58%, rgba(17,62,67,1) 100%);
    }
    &--green-lighter {
        background-color: $se_green_lighter;
    }
    &--has-leaf {
        overflow: visible;
        &:before {
            position: absolute;
            right: rem(20);
            bottom: rem(20);
            width: rem(960);
            height: rem(1060);
            content: "";
            opacity: .3;
            background: url("/public/svg/leaf-white.svg") center center / 100% no-repeat;
        }
    }
    &--blue {
        background-color: #CDE7F1;
        &:last-of-type {
            border-radius: 0 0 rem(75) 0;
        }
    }
    &--orange-light {
        background-color: $se_orange_light;
    }
    &--orange {
        background-color: $se_orange;
    }
    &--transparent {
        padding: rem(100) 0;
    }

    // Types
    &--topical {
        padding: rem(60) 0 rem(96);
        border-bottom-right-radius: rem(75);
        overflow: hidden;
    }
    &--quote {
        padding: rem(100) 0 rem(100);
    }
    &--article-slider {
        padding: rem(64) 0;
    }
    &--discover {
       padding: rem(100) 0 rem(76);
    }
    &--council {
        position: relative;
        padding: rem(90) 0 rem(150);
        overflow: hidden;
        &:before {
            position: absolute;
            top: rem(88);
            left: 50%;
            width: rem(1723);
            height: rem(1906);
            background: url("/public/svg/leaf.svg") center center / 100% no-repeat;
            transform: translateX(-50%);
            content: "";
        }
        > .container {
            position: relative;
        }
    }
    &--page-topical {
        position: relative;
        padding: rem(36) 0 rem(70) 0;
        .container {
            position: relative;
        }
        &:before {
            position: absolute;
            bottom: rem(-70);
            left: 0;
            width: 100%;
            height: calc(100% - rem(390));
            background-color: $se_grey;
            content: "";
            border-radius: rem(75) 0 0 0;
        }
    }
    &--topical-slider {
        position: relative;
        padding: rem(58) 0 rem(70);
    }
    &--file-slider {
        padding: rem(85) 0 rem(105);
    }
    &--article-first {
        padding: 0 0 rem(67);
    }
    &--article-images {
        padding: rem(40) 0 0;
    }
    &--page-file {
        padding: rem(70) 0 rem(65) 0;
        .container {
            position: relative;
        }
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(100% - rem(460));
            background-color: #CDE7F1;
            content: "";
            border-radius: rem(75) 0 rem(75) 0;
        }
        &--detail {
            padding: rem(70) 0 rem(240) 0;
            &:before {
                height: calc(100% - rem(580));
            }
            #{$self}__intro {
                margin-bottom: 0;
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    &--administrative,
    &--parking {
        &:before {
            background-color: $se_green_lighter;
            height: calc(100% - rem(460));
        }
    }
    &--more-info {
        &--blue-only {
            padding: rem(80) 0 0;
        }
    }
    &--products {
        z-index: 3;
    }
    &--bottom-search {
        margin-top: rem(-180);
    }
    &--breadcrumbs {
        &--fluid {
            > .container {
                max-width: rem(1220);
            }
        }
    }
    &--has-cta-side-block {
        position: relative;
        width: 100%;
        height: 0;
    }
    &--facts {
        padding: rem(80) 0;
    }
    &--contact {
        padding: 0 0 rem(80) 0;
    }
    &--related-articles-no-swiper {
        padding: rem(90) 0 rem(60) !important;
    }
    @include media-breakpoint-down(xxl) {
        &__title {
            &--small {
                font-size: rem(54);
                line-height: rem(60);
            }
            &--slider-small {
                font-size: rem(37);
                line-height: rem(52);
            }
        }
        &--topical {
            padding: rem(60) 0 rem(96);
        }
        &--article-slider {
            padding: rem(60) 0;
        }
        &--council {
            padding: rem(90) 0 rem(140);
        }
        &--file-slider {
            padding: rem(50) 0 rem(80);
        }
        &--page-file {
            &--detail {
                padding: rem(84) 0 rem(320) 0;
                &:before {
                    height: calc(100% - rem(580));
                }
            }
        }
        &--administrative {
            padding-bottom: rem(240);
        }
    }
    @include media-breakpoint-down(xl) {
        &__title {
            font-size: rem(60);
            line-height: rem(70);
            &--medium {
                font-size: rem(48);
                line-height: rem(52);
            }
            &--small {
                font-size: rem(52);
                line-height: rem(60);
            }
            &--slider-small {
                font-size: rem(37);
                line-height: rem(52);
            }
        }
        &--topical {
            padding: rem(40) 0 rem(86);
        }
        &--quote {
            padding: rem(90) 0 rem(90);
        }
        &--article-slider {
            padding: rem(40) 0 rem(60);
        }
        &--discover {
            padding: rem(86) 0 rem(60);
        }
        &--topical-slider,
        &--file-slider {
            padding: rem(30) 0 rem(40);
        }
        &--page-file {
            padding: rem(64) 0 rem(40) 0;
            &:before {
                height: calc(100% - rem(380));
            }
            &--detail {
                padding: rem(64) 0 rem(320) 0;
                &:before {
                    height: calc(100% - rem(500));
                }
            }
        }
        &--administrative {
            padding-bottom: rem(240);
        }
        &--council {
            padding: rem(84) 0 rem(80);
        }
    }
    @include media-breakpoint-down(lg) {
        &__title {
            &--medium {
                font-size: rem(42);
                line-height: rem(50);
            }
            &--small {
                font-size: rem(42);
                line-height: rem(50);
            }
            &--slider-small {
                font-size: rem(42);
                line-height: rem(50);
            }
        }
        &__intro {
            font-size: rem(18);
            line-height: rem(26);
            margin-top: rem(15);
            margin-bottom: rem(9);
        }
        &__hash-list {
            margin-bottom: 0;
            &--in-hero-slider {
                margin-bottom: rem(20);
            }
        }
        &__hash-li {
            padding: 0 rem(6);
        }
        &__hash {
            font-size: rem(14);
            line-height: rem(18);
            font-weight: 300;
        }
        &--quote {
            padding: rem(80) 0 rem(85);
        }
        &--page-topical {
            padding-top: rem(32);
            padding-bottom: 0;
            &:before {
                border-radius: rem(50) 0 0 0;
                height: calc(100% - rem(300));
            }
        }
        &--article-first {
            padding: 0 0 rem(40);
        }
        &--page-file {
            padding: rem(34) 0 rem(40) 0;
            &:before {
                border-radius: rem(50) 0 rem(50) 0;
            }
            &--detail {
                padding: rem(34) 0 rem(320) 0;
                &:before {
                    height: calc(100% - rem(420));
                }
            }
        }
        &--administrative {
            padding-bottom: rem(240);
        }
        &--products {
            padding: rem(20) 0;
            > .container {
                z-index: 2;
            }
        }
        &--breadcrumbs {
            &--fluid {
                background-color: #EBECEC;
            }
        }
        &--has-cta-side-block {
            display: flex;
            order: -1;
            height: auto;
        }
        &--related-articles-no-swiper {
            padding: rem(50) 0 !important;
        }
    }
    @include media-breakpoint-down(md) {
        &__title {
            font-size: rem(46);
            line-height: rem(56);
            &--medium {
                font-size: rem(30);
                line-height: rem(34);
            }
            &--small {
                font-size: rem(30);
                line-height: rem(34);
            }
            &--slider-small {
                font-size: rem(30);
                line-height: rem(34);
            }
        }
        &__intro {
            font-size: rem(14);
            line-height: rem(20);
            margin-top: rem(15);
            margin-bottom: rem(9);
        }
        &__more-link {
            font-size: rem(14);
            line-height: rem(20);
        }
        &--border-radius {
            border-radius: rem(40) 0 rem(40) 0;
        }
        &__hash-list {
            &--in-hero-slider {
                margin-bottom: rem(14);
                #{$self}__hash {
                    font-size: rem(14);
                }
            }
        }
        &--topical {
            padding: rem(40) 0 rem(62);
            border-bottom-right-radius: rem(40);
        }
        &--quote {
            padding: rem(60) 0;
        }
        &--article-slider {
            padding: rem(40) 0 rem(50);
        }
        &--discover {
            padding: rem(50) 0 rem(20);
        }
        &--council {
            padding: rem(50) 0 rem(70);
            margin-bottom: rem(60);
        }
        &--border-bottom-right-radius {
            border-bottom-right-radius: rem(40);
        }
        &--page-file {
            &--detail {
                &:before {
                    height: calc(100% - rem(500));
                }
            }
        }
        &--administrative {
            padding-bottom: rem(200);
        }
        &--more-info {
            &--blue-only {
                padding: rem(40) 0 0;
            }
        }
        &--bottom-search {
            display: none;
        }
        &--facts {
            padding: rem(40) 0 rem(30);
        }
        &--related-articles-no-swiper {
            > .container {
                max-width: 1535px !important;
            }
        }
        &--contact {
            padding: 0 0 rem(30) 0;
        }
    }
}

//
//
//
// Specific follow ups.

// Backgroundcolor from .quote to .article-images
//.section--quote + .section--article-images.section--grey {
//    position: relative;
//    padding-top: 0;
//    &:before {
//        position: absolute;
//        top: 0;
//        left: 0;
//        width: 100%;
//        height: rem(200);
//        background-color: white;
//        content: "";
//        z-index: 1;
//    }
//    > div {
//        position: relative;
//        z-index: 2;
//    }
//    @include media-breakpoint-down(lg) {
//        &:before {
//            height: rem(100);
//        }
//    }
//}

// Uncommented fase 7 feedback morning
//
// If section before .more-info is grey then extend into the .more-info section
//.section--grey + .section--more-info,
//.section--green-lighter + .section--more-info {
//    position: relative;
//    padding-top: 0;
//    &:before {
//        position: absolute;
//        top: 0;
//        left: 0;
//        width: 100%;
//        height: 50%;
//        max-height: rem(280);
//        background-color: transparent;
//        content: "";
//        z-index: 1;
//        border-bottom-right-radius: rem(75);
//    }
//    > div {
//        position: relative;
//        z-index: 2;
//    }
//}
//
//.section--green-lighter + .section--more-info {
//    &:before {
//        background-color: transparent;
//    }
//}

//.section--grey + .section--grey.section--article-images {
//    padding-top: 0;
//}

//.section--blue + .section--more-info--blue-only {
//    position: relative;
//    padding: 0;
//    &:before {
//        position: absolute;
//        top: 0;
//        left: 0;
//        width: 100%;
//        height: rem(270);
//        background-color: #CDE7F1;
//        content: "";
//        z-index: 1;
//        border-bottom-right-radius: rem(75);
//    }
//    @include media-breakpoint-down(md) {
//        &:before {
//            border-bottom-right-radius: rem(50);
//        }
//    }
//}

.section--transparent.article-slider--related-articles + .section--more-info {
    padding-top: 0;
}

.section--administrative + .section--more-info {
    margin-top: rem(-180);
}