.slider {
    $self: &;
    &__pagination {
        display: inline-flex;
        position: absolute;
        left: 50% !important;
        bottom: rem(74) !important;
        width: auto !important;
        transform: translateX(-50%);
        z-index: 2;
        height: rem(17);
        &--more-info {
            bottom: 0 !important;
            #{$self}__bullet {
                border: rem(2) solid black;
                &--active,
                &:hover {
                    background-color: $pr_orange;
                }
            }
        }
    }
    &__bullet {
        width: rem(12);
        height: rem(12);
        border-radius: 50%;
        border: rem(2) solid white;
        margin: 0 rem(10);
        cursor: pointer;
        &:hover {
            background-color: white;
        }
        &--active {
            background-color: white;
            cursor: default;
        }
    }
    &__navigation {
        position: absolute;
        top: calc(50% - rem(18));
        left: 0;
        width: 100%;
        height: 0;
        z-index: 2;
        &--more-info {
            #{$self}__nav-button {
                background-color: #D8D8D8;
                &:hover {
                    background-color: rgba(#D8D8D8, .8);
                }
            }
            #{$self}__nav-button--prev {
                left: rem(-38) !important;
            }
            #{$self}__nav-button--next {
                right: rem(-38) !important;
            }
        }
    }
    &__nav-button {
        position: absolute !important;
        top: 0;
        width: rem(36);
        height: rem(36);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(white, .5);
        border-radius: 50%;
        padding: 0;
        margin: 0;
        border: 0;
        &:focus-visible {
            @include focus(50%);
            &:before {
                outline-color: white !important;
            }
        }
        &:hover {
            background-color: white;
        }
        svg {
            transform: scale(.75);
        }
        &--prev {
            left: rem(28) !important;
            svg {
                transform: translateX(rem(-2)) scale(.75);
            }
        }
        &--next {
            right: rem(28) !important;
            svg {
                transform: rotate(180deg) translateX(rem(-1)) scale(.75);
            }
        }
        &--scroll-to {
            top: auto;
            bottom: rem(25);
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            svg {
                margin-top: rem(4);
                transform: rotate(-90deg) scale(.75);
            }
            &--alt {
                background-color: transparent !important;
                path {
                    fill: white;
                }
            }
        }
        &.swiper-button-disabled {
            opacity: .3;
            pointer-events: none;
        }
    }
    @media (max-width: 1160px) {
        &__nav-button {
            &--scroll-to {
                &--alt {
                    display: none;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &__bullet {
            width: rem(10);
            height: rem(10);
            margin: 0 rem(6);
            border-width: rem(1);
        }
        &__pagination {
            &--more-info {
                bottom: rem(-12) !important;
            }
        }
        &__navigation {
            &--more-info {
                #{$self}__nav-button--prev {
                    left: rem(10) !important;
                }
                #{$self}__nav-button--next {
                    right: rem(10) !important;
                }
            }
        }
        &__nav-button {
            width: rem(32);
            height: rem(32);
            svg {
                //width: rem(9);
            }
            &--prev {
                left: rem(10) !important;
                svg {
                    transform: scale(0.7);
                }
            }
            &--next {
                right: rem(10) !important;
                svg {
                    transform: rotate(180deg) translateX(rem(0)) scale(0.7);
                }
            }
            &--scroll-to {
                bottom: rem(10);
                svg {
                    transform: rotate(-90deg) translateX(rem(1)) scale(0.7);
                }
            }
        }
    }
}