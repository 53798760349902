@mixin button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: rem(42);
    padding: 0 rem(50) 0 rem(24);
    font-family: $interstate;
    font-weight: 700;
    font-size: rem(15);
    line-height: 1;
    border: 0;
    border-radius: rem(21);
    color: white;
    text-decoration: none !important;
    background-color: $pr_orange;
    transition: background-color .2s ease, color .2s ease, border-color .2s ease;
    white-space: nowrap;
    cursor: pointer;
    &:after {
        position: absolute;
        top: rem(8);
        right: rem(8);
        width: rem(26);
        height: rem(26);
        background: url("/public/svg/button-chevron-white.svg") top left / 100% no-repeat;
        content: "";
    }
    &:hover,
    &:focus-visible {
        background-color: $pr_green;
        color: white;
        &:before {
            display: none;
        }
    }
    &:disabled {
        pointer-events: none;
        opacity: .5;
    }
    @include media-breakpoint-down(sm) {
        height: rem(30);
        padding: 0 rem(30) 0 rem(15);
        font-size: rem(11);
        border-radius: rem(15);
        &:after {
            top: rem(5);
            right: rem(5);
            width: rem(20);
            height: rem(20);
        }
    }
}

input[type="submit"] {
    @include button;
    cursor: pointer;
}

.button {
    $self: &;
    @include button;
    &--no-arrow {
        padding: 0 rem(24);
        &:after {
            display: none;
        }
    }
    &--arrow-left {
        padding: 0 rem(24) 0 rem(50);
        &:after {
            right: 0;
            left: rem(8);
            transform: rotate(180deg);
        }
    }
    &--ghost {
        background-color: transparent;
        border: rem(1) solid $pr_orange;
        color: $pr_orange;
        &:after {
            background-image: url("/public/svg/button-chevron-orange.svg");
            transition: background-image .2s ease;
            top: rem(7);
        }
        &:hover {
            background-color: $pr_green;
            border-color: $pr_green;
            &:after {
                background-image: url("/public/svg/button-chevron-white.svg");
            }
        }
    }
    &--info {
        background-color: #646464;
        color: white;
        &:before {
            display: block;
            position: absolute;
            top: rem(8);
            right: rem(8);
            width: rem(26);
            height: rem(26);
            background-color: white;
            border-radius: 50%;
            content: "" !important;
            transition: background-color .2s ease, border .2s ease;
            border: rem(1) solid white;
        }
        &:after {
            font-family: $playfair_display;
            font-size: rem(22);
            font-style: italic;
            font-weight: 800;
            background-image: none;
            color: $pr_orange;
            content: "i";
            right: rem(-1);
            top: rem(9);
            transition: color .2s ease;
        }
        &:hover {
            background-color: #646464;
            color: white;
            &:before {
                display: block;
                content: "";
                background-color: $pr_green;
            }
            &:after {
                color: white;
            }
        }
        &--ghost {
            border: rem(1) solid #646464;
            background-color: transparent;
            color: #646464;
            &:before {
                border-color: #646464;
            }
            &:hover {
                background-color: white;
                color: #646464;
                &:before {
                    border-color: $pr_green;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &--info {
            &:before {
                width: rem(20);
                height: rem(20);
                top: rem(5);
                right: rem(5);
            }
            &:after {
                font-size: 16px;
                top: rem(6);
                right: rem(-2);
            }
            &--ghost {
                &:before {
                    top: rem(4);
                }
            }
        }
        &--ghost {
            &:after {
                top: rem(4);
                right: rem(4);
            }
        }
        &--no-arrow {
            padding: 0 rem(15);
        }
        &--arrow-left {
            padding: 0 rem(15) 0 rem(30);
            &:after {
                right: 0;
                top: rem(5);
                left: rem(5);
            }
            &#{$self}--ghost {
                &:after {
                    top: rem(4) !important;
                    left: rem(4) !important;
                }
            }
        }
    }
}

.close-button {
    height: rem(35);
    padding: 0 rem(55) 0 0;
    border: 0;
    display: flex;
    align-items: center;
    font-size: rem(15);
    font-weight: 500;
    line-height: rem(35);
    color: $txt_dark_grey;
    opacity: 0;
    transition: opacity .3s ease;
    background: transparent url("/public/svg/top-nav-close.svg") top right / rem(35) no-repeat;
    &:focus-visible {
        @include focus($pos: absolute);
    }
    &:hover {
        span {
            text-decoration: underline;
        }
    }
    @include media-breakpoint-down(xl) {
        height: rem(23);
        padding: 0 rem(40) 0 0;
        background: transparent url("/public/svg/top-nav-close.svg") top right / rem(23) no-repeat;
    }
}