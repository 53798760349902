a, button {
    &:focus-visible {
        @include focus();
    }
}
a:not(.button) {
    color: black;
    &[target="_blank"],
    &.external {
        &:after {
            display: inline-flex;
            width: rem(16);
            height: rem(16);
            background: url("/public/svg/link-new-tab.svg") center center / 100% no-repeat;
            content: "";
            margin-left: rem(6);
            transform: translateY(rem(2));
        }
        &:hover {
            text-decoration: none;
        }
    }
    &.external {
        &--white {
            &:after {
                background: url("/public/svg/link-new-tab-white.svg") center center / 100% no-repeat;
            }
        }
    }
}
p {
    a:not(.button) {
        &:hover {
            text-decoration: none;
        }
        &:focus-visible {
            outline: rem(3) solid $se_focus;
            border-radius: rem(5);
            &:before {
                display: none;
            }
        }
        &[target="_blank"],
        &.external {
            &:after {
                display: inline-flex;
                width: rem(16);
                height: rem(16);
                background: url("/public/svg/link-new-tab.svg") center center / 100% no-repeat;
                content: "";
                margin-left: rem(6);
                transform: translateY(rem(2));
            }
        }
        &.external {
            &--white {
                &:after {
                    background: url("/public/svg/link-new-tab-white.svg") center center / 100% no-repeat;
                }
            }
        }
    }
    b, strong {
        font-weight: 700;
    }
}

hr {
    background-color: #707070;
    opacity: 1;
    margin: rem(38) 0;
    @include media-breakpoint-down(md) {
        margin: rem(16) 0;
    }
}

dt, dd {
    margin-bottom: 0;
}

.table {
    border-color: #707070;
    width: calc(100% + rem(56));
    margin-left: rem(-28);
    th, td {
        font-size: rem(18);
        line-height: rem(26);
        font-weight: 300;
        padding: rem(20) rem(28);
        //&:first-of-type {
        //    padding-left: 0;
        //    border-left: 0;
        //}
        &:last-of-type {
            border-right: 0;
        }
        strong, b {
            font-weight: 700;
        }
    }
    th:not([scope='row']) {
        font-weight: 700;
        span {
            font-weight: 300;
        }
    }
    &-row-bordered {
        td, th {
            border-bottom: 0;
        }
        tr {
            border-bottom: rem(1) solid #707070;
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
    &-responsive {
        overflow-x: hidden;
    }
    &.productinfo {
        margin-bottom: 0;
        caption {
            @include visually-hidden;
        }
        th, td {
            padding: 0 rem(20) rem(10) 0;
        }
        th {
            padding-left: 0;
        }
        td {
            font-size: rem(14);
            line-height: rem(24);
            font-weight: 500;
            padding-top: rem(3);
            p, a, ul li, ol li {
                font-size: rem(14);
                line-height: rem(24);
                font-weight: 500;
            }
            h1, h2, h3, h4, h5, h6 {
                font-weight: 700;
            }
            p {
                margin-bottom: 0;
            }
            ul {
                padding-left: rem(14);
                margin-bottom: rem(10);
                //li {
                //    position: relative;
                //    &:before {
                //        position: absolute;
                //        top: 0;
                //        left: rem(-20);
                //        content: "•";
                //    }
                //}
            }
            ol {
                padding-left: rem(20);
                margin-bottom: rem(10);
            }
        }
    }
    @include media-breakpoint-down(md) {
        width: calc(100% + rem(40));
        margin-left: rem(-20);
        th, td {
            font-size: rem(14);
            line-height: rem(20);
            padding: rem(10) rem(20);
        }
        &-responsive {
            width: calc(100% + rem(40));
            transform: translateX(rem(-20));
            padding: 0 0 0 rem(20);
            position: relative;
            overflow-x: auto;
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: rem(84);
                height: rem(84);
                content: "";
                background: url("/public/svg/table-scroll.svg") center center / 100% no-repeat;
                transition: opacity .3s ease, visibility .3s ease;
                pointer-events: none;
            }
            &--scrolled {
                &:after {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            table {
                min-width: rem(770);
                margin-bottom: 0;
            }
        }
        &.productinfo {
            th, td {
                padding: 0 rem(10) rem(10) 0;
            }
            th {
                font-size: rem(11);
                line-height: rem(16);
                font-weight: 600;
            }
            td {
                font-size: rem(11);
                line-height: rem(16);
                font-weight: 300;
                padding-top: rem(1);
                p, a, ul li, ol li {
                    font-size: rem(11);
                    line-height: rem(16);
                    font-weight: 300;
                }
                h1, h2, h3, h4, h5, h6 {
                    font-weight: 600;
                }
                p {
                    margin-bottom: 0;
                }
                ol, ul {
                    margin-bottom: 0;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &-responsive {
            table {
                min-width: rem(580);
            }
        }
    }
    //&__scroll-container {
    //    overflow-x: scroll;
    //    -webkit-overflow-scrolling: touch;
    //    &--fixed {
    //        table {
    //            min-width: 0;
    //            tr {
    //                border-bottom: 0;
    //                &:first-of-type {
    //                    border-top: 0;
    //                }
    //            }
    //            th, td {
    //                border-right: 0;
    //            }
    //        }
    //    }
    //}
    @include media-breakpoint-down(md) {
        //&__scroll-container {
        //    position: relative;
        //    width: calc(100% + rem(40));
        //    transform: translateX(rem(-20));
        //    background-color: white;
        //    padding: 0 rem(20);
        //    &:after {
        //        position: absolute;
        //        top: 50%;
        //        left: 50%;
        //        transform: translateX(-50%) translateY(-50%);
        //        width: rem(84);
        //        height: rem(84);
        //        content: "";
        //        background: url("/public/svg/table-scroll.svg") center center / 100% no-repeat;
        //        transition: opacity .3s ease, visibility .3s ease;
        //        pointer-events: none;
        //    }
        //    &--scrolled {
        //        &:after {
        //            opacity: 0;
        //            visibility: hidden;
        //        }
        //    }
        //    &--fixed {
        //        width: 100%;
        //        transform: translateX(0);
        //        background-color: transparent;
        //        padding: 0;
        //        &:after {
        //            display: none;
        //        }
        //    }
        //}
    }
}

.editor {
    $self: &;
    h1, h2, h3, h4, h5, h6 {
        font-family: $interstate;
        font-weight: 700;
        line-height: 1;
        padding-top: rem(24);
        a {
            display: flex;
            margin-top: rem(-24);
            &:hover {
                text-decoration: none;
            }
        }
    }
    h2 {
        font-size: rem(31);
        margin-bottom: rem(35);
    }
    h3 {
        font-size: rem(25);
        margin-bottom: rem(26);
    }
    h4 {
        font-size: rem(21);
        margin-bottom: rem(23);
    }
    h5 {
        font-size: rem(20);
        margin-bottom: rem(20);
    }
    h6 {
        font-size: rem(19);
        margin-bottom: rem(20);
    }
    p, ul li, ol li {
        font-size: rem(18);
        line-height: rem(30);
        font-weight: 300;
        margin-bottom: rem(30);
        &#{$self}__intro {
            font-family: $playfair_display;
            font-size: rem(24);
            line-height: rem(35);
            color: black;
            margin-bottom: rem(51);
            font-weight: 400;
            margin-top: rem(74);
            &--interstate {
                font-family: $interstate;
                font-weight: 400;
                font-size: rem(18);
                line-height: rem(31);
            }
            &--no-margin-top {
                margin-top: 0;
            }
        }
    }
    ul, ol {
        padding-left: rem(20);
        li {
            margin-bottom: 0;
        }
        &#{$self}__calendar-items {
            padding-left: 0;
        }
    }
    //ul, ol {
    //    margin-bottom: rem(20);
    //}
    //ul li,
    //ol li {
    //    margin-bottom: 0;
    //    p {
    //        margin-bottom: 0;
    //    }
    //}
    //ul, ol {
    //    li {
    //        position: relative;
    //        &:before {
    //            position: absolute;
    //            top: 0;
    //            left: rem(-32);
    //            content: "•";
    //        }
    //    }
    //}

    //ul {
    //    padding-left: rem(10);
    //    li {
    //        &:before {
    //            left: rem(-10);
    //            content: "•";
    //        }
    //    }
    //}
    //ol {
    //    list-style: none;
    //    counter-reset: block_ul;
    //    li {
    //        &:before {
    //            counter-increment: block_ul;
    //            content: counter(block_ul);
    //        }
    //    }
    //}
    img {
        border-radius: rem(15);
        margin-bottom: rem(45);
    }
    form,
    .form {
        margin-bottom: rem(30);
    }
    //&__ul-dash {
    //    padding-left: rem(13) !important;
    //    li {
    //        &:before {
    //            left: rem(-13) !important;
    //            content: "—" !important;
    //        }
    //    }
    //}
    &__default-title {
        font-family: $playfair_display !important;
        font-size: rem(40);
        line-height: 1.25 !important;
        font-weight: 900 !important;
        padding-top: rem(64) !important;
        margin-bottom: rem(70) !important;
        &--small {
            margin-bottom: rem(20) !important;
        }
    }
    &__bold-link {
        font-weight: 900;
        font-size: rem(19);
        &:hover {
            text-decoration: none;
        }
    }
    &__wide-image {
        position: relative;
        display: flex;
        padding-top: rem(48);
        padding-bottom: rem(71);
        img {
            border-radius: rem(15);
            margin-bottom: rem(0);
        }
        figcaption,
        #{$self}__caption {
            font-size: rem(12) !important;
            line-height: rem(19) !important;
            color: black;
            margin-top: rem(30);
            margin-bottom: 0 !important;
            max-width: rem(713);
        }
        &--video {
            #{$self}__image-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                cursor: pointer;
                transition: opacity .3s ease, visibility .3s ease;
                &--hide {
                    opacity: 0;
                    visibility: hidden;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &--full-width {
                padding-top: rem(60);
                padding-bottom: rem(95);
                #{$self}__video-container {
                    border-radius: 0;
                    padding-bottom: 48%;
                }
            }
        }
    }
    &__video-container {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        border-radius: rem(15);
        overflow: hidden;
        #{$self}__play-button,
        #{$self}__play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.5);
        }
        #{$self}__play-button {
            border: 0;
            background: transparent;
            appearance: none;
            padding: 0;
            #{$self}__play-icon {
                position: relative;
                top: auto;
                left: auto;
                transform: translate(0, 0) scale(1);
            }
            &:focus-visible {
                &:before {
                    border-radius: 50%;
                    outline-color: $pr_orange;
                    outline-width: rem(5);
                }
            }
        }
    }
    &__video-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    &__description {
        font-size: rem(12) !important;
        line-height: rem(19) !important;
        color: black;
        margin-top: rem(30);
        margin-bottom: 0 !important;
        max-width: rem(713);
    }
    &__white-content-block {
        border-radius: rem(75) 0 rem(75) 0;
        padding: rem(60);
        padding-right: 10.5%;
        padding-left: 10.5%;
        background-color: white;
        width: 100%;
        margin: rem(80) 0;
        h1, h2, h3, h4, h5, h6 {
            padding-top: 0;
        }
        &--orange {
            background-color: $se_orange;
        }
    }
    &__lime-content-block {
        border-radius: rem(75) 0 rem(75) 0;
        padding: rem(60) rem(100);
        background-color: $pr_lime;
        width: 100%;
        margin: rem(40) 0 rem(80);
        h1, h2, h3, h4, h5, h6 {
            padding-top: 0;
        }
    }
    &__items {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0 !important;
        margin-bottom: 0 !important;
        list-style-type: none;
        li {
            position: relative;
            padding-bottom: rem(31);
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: "—";
            }
            &:before {
                display: none;
            }
            &:last-child {
                padding-bottom: 0;
                &:after {
                    display: none;
                }
            }
        }
        h1, h2, h3, h4, h5, h6, p, a {
            padding-top: 0;
            margin-bottom: 0;
            line-height: rem(31);
        }
    }
    &__calendar-items {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0 !important;
        list-style-type: none;
        li {
            position: relative;
            border-bottom: rem(1) solid rgba(black, .5);
            &:first-child {
                border-top: rem(1) solid rgba(black, .5);
            }
            &:before {
                display: none;
            }
        }
    }
    &__calendar-item {
        text-decoration: none;
        display: flex;
        flex-flow: column nowrap;
        padding: rem(20) rem(20) rem(20) 0;
        h4 {
            order: 2;
            font-size: rem(24);
            line-height: rem(30);
            margin-bottom: 0;
        }
        p {
            font-weight: 700;
            order: 1;
            font-size: rem(17);
            line-height: rem(30);
        }
        &:hover {
            #{$self}__calendar-item-chevron {
                background-color: black;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    &__calendar-item-chevron {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: rem(22);
        width: rem(26);
        height: rem(26);
        border-radius: 50%;
        transform: translateX(rem(6));
        svg {
            transform: translateX(rem(1)) scale(1.2);
        }
    }
    &__featured {
        position: relative;
        display: flex;
        padding: rem(50) rem(60) rem(23);
        background-color: #BCDBC9;
        border-radius: rem(20);
        overflow: hidden;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - rem(60));
            height: rem(950);
            transform: translate(-50%, -50%);
            content: "";
            opacity: .3;
            background: url("/public/svg/leaf-white.svg") center center / 100% no-repeat;
        }
        > div {
            position: relative;
        }
        #{$self}__featured-title {
            font-size: rem(28);
            line-height: rem(36);
            padding-top: 0;
            font-weight: 500;
        }
        .a-col {
            padding: 0;
        }
    }
    &__link-list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0 !important;
        padding-top: rem(16);
        padding-bottom: rem(16);
        border-bottom: rem(1) solid #707070;
        margin-bottom: 0 !important;
        list-style-type: none;
        li {
            font-size: rem(21);
            line-height: rem(31);
            font-weight: 700;
            &:before {
                display: none;
            }
            a {
                font-size: rem(21);
                line-height: rem(31);
                font-weight: 700;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                font-size: rem(15);
                font-weight: 300;
                padding-left: rem(5);
                &:before {
                    content: "/";
                    padding-right: rem(5);
                }
            }
        }
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            border-bottom: 0;
        }
    }
    &__vertical-list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0 !important;
        li {
            display: flex;
            flex-flow: column nowrap;
            margin-bottom: rem(26) !important;
            &:before {
                display: none;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            font-size: rem(21);
            line-height: rem(26);
            font-weight: 700;
            text-decoration: none;
            margin-bottom: 0;
            order: 2;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        p {
            font-size: rem(14);
            line-height: rem(26);
            font-weight: 300;
            margin-bottom: 0;
            &:first-of-type {
                font-weight: 700;
            }
            &:last-of-type {
                order: 3;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        a {
            font-size: rem(21);
            line-height: rem(26);
            font-weight: 700;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__mobile-navigation {
        display: none;
    }
    &__small-note {
        display: none;
    }
    &__alert {
        font-weight: 700 !important;
        color: $pr_orange;
    }
    &__narrow-title {
        max-width: calc(100% - rem(50));
    }
    &--featured {
        margin-top: rem(70);
        margin-bottom: rem(96);
    }
    &--small-paragraph {
        p, ul li, ol li {
            font-size: rem(15);
            line-height: rem(26);
        }
        table {
            th, td {
                font-size: rem(14);
            }
        }
    }
    &--alt-headings {
        h1, h2, h3, h4, h5, h6 {
            line-height: 1.3;
            padding-top: 0;
            margin-bottom: 0;
            a {
                margin-top: 0;
            }
        }
    }
    &--playfair-headings {
        h1, h2, h3, h4, h5, h6 {
            font-family: $playfair_display;
            font-weight: 900;
        }
    }
    &--sidebar {
        p, ul li, ol li {
            font-size: rem(16);
            line-height: rem(23);
            font-weight: 500;
        }
    }
    &--orange {
        h1, h2, h3, h4, h5, h6 {
            padding-top: 0;
            margin-bottom: rem(10);
            color: $pr_orange;
        }
    }
    p + .accordion {
        padding-top: rem(20);
    }
    @media (max-width: 1765px) {
        &__wide-image {
            padding-top: rem(60);
        }
    }
    @include media-breakpoint-down(xl) {
        &__description {
            max-width: rem(570);
        }
        &__wide-image {
            figcaption,
            #{$self}__caption {
                max-width: rem(570);
            }
        }
        &__featured {
            padding: rem(20) rem(30) rem(3);
            #{$self}__featured-title {
                font-size: rem(22);
                line-height: rem(30);
            }
        }
        &__lime-content-block {
            padding: rem(60) rem(80);
        }
        &__calendar-item {
            padding: rem(16) rem(16) rem(16) 0;
            h4 {
                font-size: rem(20);
                line-height: rem(26);
            }
            p {
                font-size: rem(16);
                line-height: rem(24);
            }
        }
        p, ul li, ol li {
            &#{$self}__intro {
                margin-top: rem(50);
                &--no-margin-top {
                    margin-top: 0;
                }
            }
        }
        &--featured {
            margin-top: rem(30);
            margin-bottom: rem(56);
        }
    }
    @include media-breakpoint-down(lg) {
        p, ul li, ol li {
            &#{$self}__intro {
                margin-top: rem(40);
            }
        }
        p {
            margin-bottom: rem(24);
            &#{$self}__intro {
                margin-bottom: rem(60);
                margin-top: rem(40);
                font-size: rem(20);
                line-height: rem(30);
                &--no-margin-top {
                    margin-top: 0;
                }
            }
        }
        form,
        .form {
            margin-bottom: rem(24);
        }
        &__wide-image {
            padding-bottom: rem(80);
            figcaption,
            #{$self}__caption {
                max-width: rem(400);
            }
        }
        &__description {
            max-width: rem(400);
        }
        &__video-container {
            #{$self}__play-icon {
                transform: translate(-50%, -50%);
            }
        }
        &__white-content-block {
            padding: rem(40);
            margin: rem(40) 0;
        }
        &__lime-content-block {
            padding: rem(60);
            margin: rem(30) 0 rem(60);
        }
        &__mobile-navigation {
            display: flex;
            margin: rem(40) 0 rem(20);
            width: calc(100% + rem(30));
            transform: translateX(rem(-15));
            ul {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                padding-left: 0 !important;
                width: 100%;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    font-size: rem(18) !important;
                    line-height: rem(26) !important;
                    font-weight: 700;
                    &:before {
                        display: none;
                    }
                }
            }
            &__arrow {
                display: flex;
                width: rem(40);
                height: rem(40);
                background: url("/public/svg/chevron-left-black.svg") center center / rem(9) no-repeat;
                &--next {
                    transform: rotate(180deg);
                }
            }
        }
        &__vertical-list {
            p, h1, h2, h3, h4, h5, h6 {
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-down(md) {
        h2, h3, h4, h5, h6 {
            line-height: 1.3;
        }
        h2 {
            font-size: rem(18);
            margin-bottom: rem(25);
        }
        h3 {
            font-size: rem(16);
            margin-bottom: rem(25);
        }
        h4 {
            font-size: rem(15);
            margin-bottom: rem(25);
        }
        h5 {
            font-size: rem(14);
            margin-bottom: rem(25);
        }
        h6 {
            font-size: rem(14);
            margin-bottom: rem(25);
        }
        p, ul li, ol li {
            font-size: rem(14);
            line-height: rem(22);
            &#{$self}__intro {
                font-size: rem(18);
                line-height: rem(28);
                margin-bottom: rem(40);
                margin-top: rem(30);
                &--no-margin-top {
                    margin-top: 0;
                }
            }
        }
        ul, ol {
            padding-left: rem(10);
            li {
                &:before {
                    left: rem(-10);
                }
            }
        }
        &__wide-image {
            padding-top: rem(20);
            padding-bottom: rem(24);
            figcaption,
            #{$self}__caption {
                font-size: rem(11) !important;
                line-height: rem(16) !important;
                margin-top: rem(20);
            }
            &--video {
                &--full-width {
                    padding-top: rem(20);
                    padding-bottom: rem(25);
                    #{$self}__video-container {
                        padding-bottom: 81%;
                    }
                }
            }
        }
        &__description {
            font-size: rem(11) !important;
            line-height: rem(16) !important;
            margin-top: rem(20);
        }
        &__video-container {
            #{$self}__play-icon {
                transform: translate(-50%, -50%) scale(.7);
            }
        }
        &__items {
            padding-left: 0 !important;
            li {
                padding-bottom: rem(22);
            }
            h1, h2, h3, h4, h5, h6, p, a {
                margin-bottom: 0;
            }
        }
        &__white-content-block {
            border-radius: rem(50) 0 rem(50) 0;
            padding: rem(40) rem(30);
            margin: rem(20) 0;
            &--mobile-full-width {
                width: calc(100% + rem(40));
                transform: translateX(rem(-20));
            }
        }
        &__lime-content-block {
            border-radius: rem(45) 0 rem(45) 0;
            padding: rem(45) rem(30);
            .col-12 {
                &:not(:first-of-type) {
                    #{$self}__calendar-items li {
                        &:first-child {
                            border-top-color: transparent;
                        }
                    }
                }
            }
        }
        &__calendar-items {
            padding-left: 0 !important;
        }
        &__calendar-item {
            padding: rem(10) rem(10) rem(10) 0;
            h4 {
                font-size: rem(18);
                line-height: rem(26);
                margin-bottom: 0;
            }
            p {
                font-size: rem(14);
                line-height: rem(24);
            }
        }
        &__calendar-item-chevron {
            bottom: rem(13);
            width: rem(19);
            height: rem(19);
            svg {
                transform: translateX(rem(1)) scale(.85);
            }
        }
        &__featured {
            padding: rem(30) rem(30) rem(20) rem(30);
            &:before {
                width: 100%;
                background-size: 150%;
            }
        }
        &__default-title {
            padding-top: rem(24) !important;
            margin-bottom: rem(36) !important;
            font-size: rem(24);
            &--small {
                margin-bottom: rem(8) !important;
            }
        }
        &__link-list {
            padding-left: 0 !important;
            li {
                font-size: rem(18);
                line-height: rem(23);
                margin-bottom: rem(20) !important;
                &:last-child {
                    margin-bottom: 0 !important;
                }
                a {
                    display: flex;
                    flex-flow: column nowrap;
                    font-size: rem(18);
                    line-height: rem(23);
                    text-decoration: underline;
                }
                span {
                    padding-left: 0;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &__vertical-list {
            padding-left: 0 !important;
            margin-bottom: 0 !important;
            a {
                text-decoration: underline;
            }
            p, h1, h2, h3, h4, h5, h6 {
                margin-bottom: 0;
            }
        }
        &__mobile-navigation {
            margin: rem(16) 0;
            &--bottom {
                margin-bottom: rem(40);
            }
        }
        &__small-note {
            display: flex;
            font-size: rem(10) !important;
            line-height: rem(16);
            font-style: italic;
            color: #767676;
            font-weight: 300;
            margin: rem(10) 0;
        }
        &--featured {
            margin-top: rem(20);
            margin-bottom: rem(40);
        }
        &--alt-headings {
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: rem(8);
            }
        }
        &--playfair-headings {
            h1 {
                font-size: rem(24);
            }
        }
        &--orange {
            h1, h2, h3, h4, h5, h6 {
                padding-top: 0;
                margin-bottom: rem(6);
            }
        }
    }
}

.editor__default-title + .editor__intro--interstate {
    margin-top: rem(-30) !important;
    @include media-breakpoint-down(md) {
        margin-top: rem(-10) !important;
    }
}

.bg {
    &__pr-orange {
        background-color: $pr_orange !important;
    }
    &__pr-lime {
        background-color: $pr_lime !important;
    }
    &__pr-green {
        background-color: $pr_green !important;
    }
    &__se-orange {
        background-color: $se_orange !important;
    }
    &__se-orange-light {
        background-color: $se_orange_light !important;
    }
    &__se-grey {
        background-color: $se_grey !important;
    }
    &__se-green-light {
        background-color: $se_green_light !important;
    }
    &__se-green-lighter {
        background-color: $se_green_lighter !important;
    }
    &__se-green-medium {
        background-color: $se_green_medium   !important;
    }
    &__se-blue-light {
        background-color: $se_blue_light !important;
    }
    &__se-blue {
        background-color: $se_blue !important;
    }
    &__se-blue-medium {
        background-color: $se_blue_medium !important;
    }
    &__se-blue-dark {
        background-color: $se_blue_dark !important;
    }
    &__se-focus {
        background-color: $se_focus !important;
    }
}
.text {
    &__white {
        color: white !important;
    }
    &__black {
        color: black !important;
    }
}