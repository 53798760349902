.header {
    $self: &;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(80);
        padding: 0 rem(20) 0 rem(20);
        background-color: white;
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            content: "";
            box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.16);
        }
    }
    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        width: rem(116);
        &:focus-visible {
            @include focus(rem(10), rem(30), absolute, rem(34));
        }
    }
    &__logo-img {
        width: 100%;
    }
    &__button-container {
        display: flex;
    }
    &__menu-button {
        position: relative;
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;
        padding: 0;
        &[aria-expanded="true"] {
            #{$self}__menu-button-icon {
                span {
                    &:first-of-type {
                        transform: rotate(-45deg);
                        top: rem(22);
                        left: rem(6);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                        max-width: rem(38);
                    }
                    &:last-of-type {
                        transform: rotate(45deg);
                        top: rem(-6);
                        left: rem(6);
                    }
                }
            }
            #{$self}__menu-button-text {
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;
                &--close {
                    width: auto;
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                    transition: opacity .3s .2s ease, visibility .3s .2s ease;
                }
            }
        }
        &--search-mobile {
            display: none;
        }
        &:focus-visible {
            @include focus(rem(10), rem(30), relative, rem(38));
        }
        &:hover {
            #{$self}__menu-button-text {
                text-decoration: underline;
            }
        }
    }
    &__menu-button-icon {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: rem(38);
        height: rem(21);
        span {
            width: 100%;
            max-width: rem(38);
            height: rem(3);
            background-color: $pr_blue;
            transition: all .25s ease;
            transform-origin: left center;
            &:first-of-type {
                position: absolute;
                top: 0;
                left: 0;
            }
            &:last-of-type {
                position: absolute;
                top: rem(19);
                left: 0;
            }
        }
    }
    &__menu-button-text {
        font-family: $interstate;
        font-size: rem(20);
        line-height: rem(36);
        margin-left: rem(12);
        font-weight: 500;
        color: $pr_blue;
        transition: opacity .3s .2s ease, visibility .3s .2s ease;
        &--close {
            display: flex;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            height: 0;
            width: 0;
            overflow: hidden;
            transition: opacity .3s ease, visibility .3s ease;
            top: 0;
            right: auto;
            left: rem(38);
            transform: translateX(0);
        }
    }
    &__top-nav {
        display: flex;
        flex-flow: row nowrap;
        gap: rem(30);
    }
    &__top-nav-link {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        text-decoration: none;
        &:focus-visible {
            @include focus(rem(10), rem(26), relative, rem(26));
        }
        &:hover {
            #{$self}__top-nav-link-text {
                text-decoration: underline;
                text-decoration-thickness: rem(2);
            }
        }
    }
    &__top-nav-link-icon {
        height: rem(31);
        width: auto;
    }
    &__top-nav-link-text {
        font-family: $interstate;
        font-size: rem(11);
        line-height: rem(14);
        font-weight: 700;
        color: $pr_blue;
        text-align: center;
        margin-top: rem(4);
    }
    > .container-fluid {
        position: relative;
        z-index: 2;
    }
    //@include media-breakpoint-down(xxl) {
    //    &__inner {
    //        height: rem(90);
    //    }
    //    &__top-nav-link-icon {
    //        height: rem(34);
    //    }
    //    &__menu-button {
    //        &:focus-visible {
    //            @include focus(rem(10), rem(30), relative, rem(30));
    //        }
    //    }
    //    &__menu-button-icon {
    //        width: rem(40);
    //    }
    //    &__menu-button-text {
    //        font-size: rem(24);
    //        margin-left: rem(18);
    //    }
    //    &__top-nav-link {
    //        &:focus-visible {
    //            @include focus(rem(10), rem(26), relative, rem(26));
    //        }
    //    }
    //}
    @include media-breakpoint-down(lg) {
        &__inner {
            height: rem(46);
            padding: 0 rem(16) 0 rem(14);
        }
        &__logo {
            position: absolute;
            width: rem(118);
            left: 50%;
            right: rem(20);
            transform: translateX(-50%) translateY(-50%);
            &:focus-visible {
                @include focus(rem(10), rem(30), absolute, rem(15));
            }
        }
        &__menu-button {
            position: relative;
            flex-flow: column nowrap;
            height: rem(46);
            width: rem(40);
            padding-top: rem(9);
            &--search-mobile {
                display: flex;
                margin-left: rem(22);
                padding-top: rem(8);
                transition: opacity .2s ease;
                &--open {
                    opacity: 0;
                }
                #{$self}__menu-button-icon {
                    width: rem(17);
                    height: rem(17);
                    transform: translateY(0) !important;
                }
                #{$self}__menu-button-text {
                    line-height: rem(18);
                    opacity: 1 !important;
                }
            }
            &:focus-visible {
                @include focus(rem(10), rem(10), relative, rem(0));
            }
            &[aria-expanded="true"] {
                #{$self}__menu-button-text {
                    opacity: 0;
                    &--close {
                        visibility: visible;
                        opacity: 1 !important;
                    }
                }
                #{$self}__menu-button-icon {
                    transform: translateY(rem(6));
                    span {
                        &:first-of-type {
                            left: rem(2);
                            top: rem(16);
                            transform: rotate(-45deg);
                        }
                        &:nth-of-type(2) {
                            opacity: 0;
                        }
                        &:last-of-type {
                            left: rem(2);
                            top: rem(-2);
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        &__menu-button-icon {
            width: rem(25);
            height: rem(14);
            transition: transform .2s ease;
            span {
                height: rem(2);
                &:last-of-type {
                    top: rem(12);
                }
            }
        }
        &__menu-button-text {
            font-size: rem(12);
            line-height: rem(20);
            margin-left: 0;
            transition: opacity .2s ease;
            opacity: 1;
            &--close {
                font-size: rem(15) !important;
                line-height: rem(27);
                visibility: hidden;
                opacity: 0 !important;
                top: rem(9);
            }
        }
        &__top-nav {
            transform: translateY(.1rem);
        }
        &__top-nav-link {
            &--mobile-hidden {
                display: none;
            }
            #{$self}__top-nav-link-icon {
                width: rem(17);
                height: rem(17);
                transform: translateY(0) !important;
            }
            #{$self}__top-nav-link-text {
                font-size: rem(12);
                line-height: rem(18);
                margin-top: 0;
                font-weight: 500;
                opacity: 1 !important;
            }
            &:focus-visible {
                @include focus(rem(10), rem(10), relative, rem(6));
            }
        }
    }
}