.article-content {
    >.container {
        position: relative;
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(69);
        line-height: rem(75);
        font-weight: 400;
        color: black;
        text-align: center;
        //margin: rem(74) 0 rem(70) 0;
        margin-top: rem(74);
        &--small {
            font-weight: 900;
            font-size: rem(40);
            line-height: rem(56);
            margin-bottom: rem(37);
            text-align: left;
        }
    }
    &__meta {
        display: flex;
        flex-flow: row wrap;
        //margin-bottom: rem(70);
        margin-top: rem(70);
        padding-left: 0;
        list-style-type: none;
        li {
            font-size: rem(18);
            line-height: rem(22);
            font-weight: 300;
            padding: 0 rem(18);
            &:not(:first-child) {
                border-left: rem(1) solid black;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &--regular {
            li {
                font-weight: 500;
            }
        }
    }
    &__content-custom-880 {
        max-width: rem(706);
    }
    &--has-three-blocks {
        padding-bottom: rem(220);
    }
    @include media-breakpoint-down(xl) {
        &__title {
            font-size: rem(60);
            line-height: rem(70);
            //margin: rem(50) 0 rem(80) 0;
            margin-top: rem(50);
            &--small {
                font-size: rem(40);
                line-height: rem(56);
                margin-bottom: rem(37);
            }
        }
        &__meta {
            //margin-bottom: rem(80);
            margin-top: rem(50);
        }
    }
    @include media-breakpoint-down(lg) {
        &__title {
            //margin: rem(40) 0 rem(40) 0;
            margin-top: rem(40);
            font-size: rem(40);
            line-height: rem(50);
        }
        &__meta {
            //margin-bottom: rem(60);
            margin-top: rem(40);
        }
        &--has-three-blocks {
            padding-bottom: rem(320);
        }
    }
    @include media-breakpoint-down(md) {
        &__title {
            font-size: rem(30);
            line-height: rem(34);
            //margin: rem(30) 0 rem(23) 0;
            margin-top: rem(30);
        }
        &__meta {
            margin-top: rem(20);
            li {
                padding: 0 rem(10);
                font-size: rem(11);
                line-height: rem(14);
            }
        }
    }
}