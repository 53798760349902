.appointment-modal {
    $self: &;
    &__dialog {
        max-width: rem(680);
        width: 100%;
    }
    &__content {
        position: relative;
        border-radius: rem(50) 0 rem(50) 0;
        background-color: white;
        padding: rem(35) rem(30) rem(50) rem(30);
        border: 0;
    }
    &__body {
        padding: rem(1);
    }
    &__close-button {
        position: absolute;
        top: rem(20);
        right: rem(20);
        opacity: 1;
    }
    @include media-breakpoint-down(lg) {
        &__content {
            padding: rem(35) rem(20) rem(30) rem(20);
        }
    }
    @include media-breakpoint-down(md) {
        &__dialog {
            margin: rem(20);
            width: auto;
        }
        &__content {
            border-radius: rem(20) 0 rem(20) 0;
            padding: rem(45) rem(0) rem(20) rem(0);
        }
    }
}